<template>
  <div class="schedule-location-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Schedule' }"
        >檔期管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>展區管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          展區列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateScheduleLocation' })"
            >新增展區
          </el-button>
        </div>
      </table-title>
      <el-table :data="schedule_location_data">
        <el-table-column
          label="ID"
          prop="schedule_location_id"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="180">
          <template v-slot="{ row }">
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="
                $router.push({
                  name: 'EditScheduleLocation',
                  params: { id: row.schedule_location_id },
                })
              "
              >編輯</el-button
            >
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="handleDelete(row.schedule_location_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  getScheduleLocationsPagination,
  deleteScheduleLocation,
} from "@/api/schedule-location";

export default {
  name: "ScheduleLocation",
  data() {
    return {
      schedule_location_data: [],
      total: 0,
      setup: {
        limit: 25,
        page: 0,
      },
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }

    this.handleGetScheduleLocation();
  },
  methods: {
    async handleGetScheduleLocation() {
      const { results, total } = await getScheduleLocationsPagination(
        this.setup
      );

      this.schedule_location_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetScheduleLocation();
    },
    handleDelete(schedule_location_id) {
      this.$confirm("是否刪除該展區", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteScheduleLocation(schedule_location_id);
          await this.handleGetScheduleLocation();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
